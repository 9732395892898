/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

body {
    min-width: 1000px;
}


/* ------------------------------------------- */
.mobile_tab {
    display: none !important;
}


// @media (min-width: 700px) {
//     .mobile_tab {
//         display: none !important;
//     }
// }

// @media(max-width:701px) {
//     .top_bar {
//         display: none !important;
//     }


// }

/* ------------------------------------------- */

ion-modal {
    --border-radius: 10px;
}

ion-modal {
    ion-button {
        max-width: 100%
    }

    .card{
        max-width: 100%
    }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: 'Montserrat', Helvetica;
}

input,
textarea,
select {
    background: transparent;
}

.close_btn {
    font-size: 36px;
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 999;
}

.loginModal {
    --height: 536px;
}

h1,
h2,
h3,
h4,
h5,
p {
    margin: 0;
}

.btn {
    color: #fff;
    background: #FF385C;
    border-radius: 10px;
    padding: 18px;
    font-size: 24px;
    line-height: 33px;
}

.ion-activatable {
    position: relative !important;
}

.btn-outline {
    background: transparent !important;
    border: 1px solid #000000 !important;
    color: #000000 !important;
}

.btn-large {
    width: 100%;
}

.wrapper {
    padding: 16px;
    display: flex;
}


.bottom_block {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 16px;
}

.form-block {
    display: flex;
    flex-direction: column;
    border: 1px solid #D7D7D7;
    border-radius: 10px;
    padding: 12px 21px;
    margin-bottom: 16px;

    label {
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #000000;
        margin-bottom: 3px;
    }
}

.form-control {
    border: 0;
    font-size: 16px;
    line-height: 140%;
    color: #000000;

}

a {
    color: #000000;
    text-decoration: underline;
    line-height: 140%;
}

.alert-wrapper {
    border-radius: 10px !important;
}

*:focus {
    outline: none;
}



.header {
    padding: 36px 16px 31px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h1 {
        margin: 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
    }

    img {
        height: 24px;
        width: 24px;
    }
}

.request-type {
    margin-left: 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    margin-bottom: 12px;
}

.cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-bottom: 60px;

}

.card {
    min-height: 100px;
    width: calc(100vw - 32px);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    border-radius: 16px;

    .photo {
        height: 100px;
        width: 112px;
        min-width: 112px;
        background-image: url('./assets/shale.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 16px 0px 0px 16px;
    }

    .info {
        padding: 9px 16px 13px 0px;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        height: 100%;

        h2 {
            font-weight: 700;
            font-size: 15px;
            line-height: 140%;
            color: #000000;
            max-width: 80%;
            overflow: hidden;
        }

        .from-to {
            font-weight: 400;
            font-size: 10px;
            line-height: 140%;
            color: #000000;
        }

        .date {
            font-weight: 500;
            font-size: 12px;
            line-height: 140%;
            color: #000000;
            position: absolute;
            top: 12px;
            right: 16px;
        }

        .price {
            font-weight: 700;
            font-size: 14px;
            line-height: 140%;
            color: #000000;
            position: absolute;
            bottom: 13px;
            right: 16px;
        }

        .bottom {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 14px;
            gap: 12px;

            .ava {
                height: 29px;
                width: 29px;
                background-image: url('./assets/ava.png');
                background-position: center !important;
                background-repeat: no-repeat !important;
                background-size: cover !important;
                border-radius: 100%;
            }

            .person {
                display: flex;
                flex-direction: column;

                h4 {
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 140%;
                    color: #000000;
                }

                .number {
                    font-weight: 500;
                    font-size: 8px;
                    line-height: 140%;
                    color: #6D6D6D;
                }
            }
        }
    }
}

.button-large-red {
    background: #FF385C;
    border-radius: 10px;
    height: 48px;
    width: calc(100vw - 32px);
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-align: center;

    color: #FFFFFF;

    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.button-large-outline {
    background: #FFFFFF;
    border: 1px solid #FF385C;
    border-radius: 10px;
    height: 48px;
    width: calc(100vw - 32px);
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-align: center;

    color: #FF385C;

    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.message-time {
    position: absolute;
    bottom: 9px;
    right: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #000000;
}

.message-mini {
    margin-top: 7px;
    max-width: 80%;
    overflow: hidden;
    height: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #000000;

    b {
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
    }
}

.dot {
    height: 9px;
    width: 9px;
    position: absolute;
    right: 16px;
    top: 46px;
}




.down {
    position: absolute;
    right: calc(25% + 16px);
    margin-top: 13px;
    height: 24px;
    width: 24px;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}



select {
    border: none;
}


.mt-16 {
    margin-top: 16px;
}

//padddings

.pb-18 {
    padding-bottom: 18px;
}

.mb-16 {
    margin-bottom: 16px;
}